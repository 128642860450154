/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 17:31:52
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-22 18:09:14
 */
import account from './account'
import recordTable from './recordTable'

export default {
  name: 'FinanceTable',
  components: {
    account,
    recordTable
  }
}
