/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 17:17:56
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-22 17:33:16
 */
import Finance from './Finance.vue'
export default Finance
