/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 17:17:52
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-22 17:34:50
 */
import FinanceTable from './FinanceTable'

export default {
  name: 'Finance',
  components: {
    FinanceTable
  }
}
