/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 17:31:56
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-22 17:42:33
 */
import FinanceTable from './FinanceTable.vue'
export default FinanceTable
